<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <h4 class="mb-0">Subject Information</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Subject Name -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Name" label-for="subject-name">
            <b-form-input id="subject-name" required v-model="subjectDataInfo.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Pathway Cost -->
        <b-col cols="12" lg="2">
          <b-form-group label="Pathway Cost" label-for="subject-cost">
            <b-input-group prepend="$">
              <cleave id="subject-cost" v-model="subjectDataInfo.cost" class="form-control" :raw="true"
                :options="options.number" placeholder="0" />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Visibility -->
        <b-col cols="12" lg="2" md="6" xs="12">
          <b-form-group label="Visibility" label-for="subject-visibility">
            <v-select v-model="subjectDataInfo.visibility" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="visibilityOptions" :clearable="false" required input-id="subject-visibility" />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col cols="12" lg="2" md="6" xs="12">
          <b-form-group label="Status" label-for="subject-status">
            <v-select v-model="subjectDataInfo.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions" :clearable="false" required input-id="subject-status" />
          </b-form-group>
        </b-col>

        <!-- Field: Subject Avatar -->
        <b-col cols="12" lg="4" md="6" xs="12">
          <b-form-group label="Avatar Url" label-for="subject-avatar">
            <b-form-input id="subject-avatar" v-model="subjectDataInfo.avatar" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button variant="outline-secondary" class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="$router.back()">
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BInputGroup, BButton } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/views/apps/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    subjectData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  metaInfo() {
    const title = this.subjectData.name ? (`${this.subjectData.name} - Edit`) : 'Edit Subject';
    return {
      title,
    };
  },
  watch: {
    subjectData: function () {
      this.inheritSelects();
    },
  },
  methods: {
    inheritSelects() {
      if (this.subjectData.status && !this.subjectData.status.value) {
        this.subjectDataInfo.status = this.statusOptions.filter((s) => s.value === this.subjectData.status)[0];
      }

      if (this.subjectData.visibility && !this.subjectData.visibility.value) {
        this.subjectDataInfo.visibility = this.visibilityOptions.filter((s) => s.value === this.subjectData.visibility)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.subjectDataInfo;
      console.log(pwd);

      store
        .dispatch('app-roster/updateSubject', {
          subjectId: pwd.id,
          subjectData: {
            name: pwd.name,
            avatar: pwd.avatar,
            visibility: pwd.visibility.value,
            cost: pwd.cost,
            status: pwd.status.value,
          },
        })
        .then((response) => {
          this.$emit('update:subject-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Subject Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit subject',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const subjectDataInfo = ref(props.subjectData);

    const SUBJECT_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(SUBJECT_APP_STORE_MODULE_NAME))
      store.registerModule(SUBJECT_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(SUBJECT_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: 'Created', value: 'created' },
      { label: 'Live', value: 'live' },
      { label: 'Retired', value: 'retired' },
    ];

    const visibilityOptions = [
      { label: 'Archived', value: 'archived' },
      { label: 'Internal', value: 'internal' },
      { label: 'Public', value: 'public' },
    ];

    return {
      subjectDataInfo,
      statusOptions,
      visibilityOptions,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
